@charset 'utf-8';

@import 'settings';
@import 'foundation';
@import 'motion-ui';

// Global styles
@include foundation-global-styles;
@include foundation-forms;
@include foundation-typography;

// Grids (choose one)
@include foundation-xy-grid-classes;
// @include foundation-grid;
// @include foundation-flex-grid;

// Generic components
@include foundation-button;
@include foundation-button-group;
@include foundation-close-button;
@include foundation-label;
@include foundation-progress-bar;
@include foundation-slider;
@include foundation-switch;
@include foundation-table;
// Basic components
@include foundation-badge;
@include foundation-breadcrumbs;
@include foundation-callout;
@include foundation-card;
@include foundation-dropdown;
@include foundation-pagination;
@include foundation-tooltip;

// Containers
@include foundation-accordion;
@include foundation-media-object;
@include foundation-orbit;
@include foundation-responsive-embed;
@include foundation-tabs;
@include foundation-thumbnail;
// Menu-based containers
@include foundation-menu;
@include foundation-menu-icon;
@include foundation-accordion-menu;
@include foundation-drilldown-menu;
@include foundation-dropdown-menu;

// Layout components
@include foundation-off-canvas;
@include foundation-reveal;
@include foundation-sticky;
@include foundation-title-bar;
@include foundation-top-bar;

// Helpers
@include foundation-float-classes;
@include foundation-flex-classes;
@include foundation-visibility-classes;
@include foundation-prototype-classes;

// Motion UI
@include motion-ui-transitions;
@include motion-ui-animations;


@font-face {
    font-family: 'AlternateGotNo2D';
    src: url('../fonts/AlternateGotNo2D.eot');
    src: url('../fonts/AlternateGotNo2D.eot?#iefix') format('embedded-opentype'),
        url('../fonts/AlternateGotNo2D.woff2') format('woff2'),
        url('../fonts/AlternateGotNo2D.woff') format('woff'),
        url('../fonts/AlternateGotNo2D.ttf') format('truetype'),
        url('../fonts/AlternateGotNo2D.svg#AlternateGotNo2D') format('svg');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: "Source Code Variable";
    src: url("02a670d8eb6755beb38a5d2353d2b81f.eot"); /* IE9*/
    src: url("../fonts/02a670d8eb6755beb38a5d2353d2b81f.eot?#iefix") format("embedded-opentype"), /* IE6-IE8 */
    url("../fonts/02a670d8eb6755beb38a5d2353d2b81f.woff2") format("woff2"), /* chrome、firefox */
    url("../fonts/02a670d8eb6755beb38a5d2353d2b81f.woff") format("woff"), /* chrome、firefox */
    url("../fonts/02a670d8eb6755beb38a5d2353d2b81f.ttf") format("truetype"), /* chrome、firefox、opera、Safari, Android, iOS 4.2+*/
    url("../fonts/02a670d8eb6755beb38a5d2353d2b81f.svg#Source Code Variable") format("svg"); /* iOS 4.1- */
    font-weight: normal;
    font-style: normal;
    font-display: swap;
  }

  @font-face {
    font-family: "flaticon";
    src: url("../fonts/flaticon.ttf?a7da5bceaf4ff8e00f108e8e4ce64ff2") format("truetype"),
url("../fonts/flaticon.woff?a7da5bceaf4ff8e00f108e8e4ce64ff2") format("woff"),
url("../fonts/flaticon.woff2?a7da5bceaf4ff8e00f108e8e4ce64ff2") format("woff2"),
url("../fonts/flaticon.eot?a7da5bceaf4ff8e00f108e8e4ce64ff2#iefix") format("embedded-opentype"),
url("../fonts/flaticon.svg?a7da5bceaf4ff8e00f108e8e4ce64ff2#flaticon") format("svg");
}

i[class^="flaticon-"]:before, i[class*=" flaticon-"]:before {
    font-family: flaticon !important;
    font-style: normal;
    font-weight: normal !important;
    font-variant: normal;
    text-transform: none;
    line-height: 1;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.flaticon-instagram-logo:before {
    content: "\f101";
}
.flaticon-twitter:before {
    content: "\f102";
}
.flaticon-facebook:before {
    content: "\f103";
}

  $headings: 'AlternateGotNo2D';
  $body: "Source Code Variable";
  $orange: #FF4E00;

  .grid-container {
      max-width: 90%;
  }
  html,body {
      padding: 0;
      margin: 0;
      font-size: .85rem;
      font-family: $body;
      height: 100%;
  }
  .logo {
      position: relative;
      z-index: 900;
  }
  video {
    object-fit: cover;
    width: 100%;
    height: 100%;
    position: absolute;
    z-index: 1;
    top: 0;
    left: 0;
    @include breakpoint(small only) { 
       object-fit: cover;
    }
}
  p {
      font-size: 1.3rem;
      color: gray;
      @include breakpoint(small only) { 
        font-size: 1.1rem;
    }
  }
  #maincontainer {
      position: relative;
      height: 100%;

  }
  header {
      position: relative;
    //   z-index: 1000;
  }
#landing {
    width: 100vw;
    height: 100%;
    position: relative;
    // z-index: 10;
}
.bgBlack{
    background-color: black;
}
.bgOrange {
    background-color: #FF4E00;
}
.bgtalent {
    background-image: url(../img/bgtalent.jpg);
    background-repeat: no-repeat;
    background-attachment: fixed;
    background-size: cover;
    @include breakpoint(small only) {
        background-attachment: scroll;
        background-position: center;
        background-size: 400%;
    }
}
.bgmusic {
    background-image: url(../img/bgmusic.jpg);
    background-repeat: no-repeat;
    background-attachment: fixed;
    background-size: cover;
    @include breakpoint(small only) {
        background-attachment: scroll;
        background-position: 60%;
        background-size: 400%;
    }
}
.bgCulture {
    background-image: url(../img/bgculture.jpg);
    background-repeat: no-repeat;
    background-attachment: fixed;
    background-size: cover;
    @include breakpoint(small only) {
        background-attachment: scroll;
        background-position: 60%;
        background-size: 400%;
    }
}
.culturehighlight {
    background-image: url(../img/bgcculture.jpg);
    background-repeat: no-repeat;
    background-attachment: fixed;
    background-size: cover;
    padding: 10vh 0!important;
}
#talcon img {
    filter: grayscale(100%);
    transition: all 1s;
    @include breakpoint(small only) {
        margin-bottom: 1.5rem;
    }
}
#talcon img:hover {
    filter: grayscale(0);
}
.white {
    color: white!important;
}
.orange {
    color: #FF4E00;
}
ul {
    li {
        font-size: 1.3rem;
        color: gray;
        text-rendering:optimizeLegibility;
        @include breakpoint(small only) {
            font-size: 1.1rem;
        }
    }
}
hr {
    border-bottom: 1px solid #ccc;
    margin: 4rem auto;
    @include breakpoint(small only) {
        margin: 2rem auto;
    }
}
.padUD {
    padding: 3rem 0;
}
.padUD2 {
    padding: 7rem 0;
}

h1 {
    font-family: $headings;
    font-size: 10rem;
    line-height: 80%;
    letter-spacing: -3px;
    @include breakpoint(small only) { 
        font-size: 5rem;
    }
}
h2 {
    font-family: $headings;
    font-size: 2.5rem;
    text-transform: uppercase;
    margin-bottom: 2rem;
    // letter-spacing: -3px;
}
h3 {
    font-family: $body;
    letter-spacing: -1px;
    font-size: 1.7rem;
    @include breakpoint(small only) { 
        font-size: 1.2rem;
    }
}
h4 {
    font-family: $headings;
    font-size: 1.5rem;
    color: gray;
    letter-spacing: 1px;
    text-transform: uppercase;
    @include breakpoint(small only) { 
        font-size: 1.2rem;
    }
}
.menu {
    li a {
        font-family: $headings;
        color: white;
        font-size: 1.7rem;
        text-transform: uppercase;
        transition: all 0.3s;
        padding: 0;
        padding: 0.5rem 1rem;
        // padding-bottom: 2rem;
        line-height: 100%;
        border-radius: 3rem;
        
    }
    li a:hover {
        background-color: white;
        color: black;
        @include breakpoint(small only) {
            background-color: transparent;
            color: $orange;
        }

    }
}
.special2 {
    li a:hover {
        background-color: transparent;
    }
}
.special {
    li {
        margin-right: 1.5rem;
    }
    li a {
        font-size: 3.5rem;
        @include breakpoint(small only) { 
            font-size: 4rem;
        }
    }
    li:last-child {
        margin: 0;
    }
    margin-bottom: 2.5rem;
}
.special2 {
    li a {
        font-size: 2.5rem;
        @include breakpoint(small only) { 
            font-size: 2rem;
        }
    }
    margin-bottom: 2.5rem;
    @include breakpoint(small only) { 
        margin-bottom: 1.5rem;
    }
}
.social a {
    font-size: 1.7rem!important;
    @include breakpoint(small only) { 
        font-size: 1.5rem!important;
    }
}
.wwd {
    img {
        max-width: 35%;
        margin-bottom: 2rem;
    }
}
.statement {
    position: absolute;
    z-index: 900;
    width: 100%;
    left: 0;
    bottom: 4rem;
}
.pl-logos {
    .cell img {
        max-width: 45%!important;
    }
    .cell {
        margin-bottom: 6rem;
    }
}
.pl-logos .cell:last-child {
    margin-bottom: 0;
}
.pl-logos .cell:nth-child(12)  {
    margin-bottom: 0;
}
.pl-logos .cell:nth-child(11)  {
    margin-bottom: 0;
}
.pT1 {
    padding-top: 3rem;
}
.imprint {
    font-size: .8rem;
    color: white;
}
.big {
    font-size: 35rem;
    line-height: 100%;
    margin-top: -7rem;
    text-transform: uppercase;
    @include breakpoint(small only) { 
        margin-top: -2rem;
        font-size: 10rem!important;
    }
}
.bigger {
    
    @include breakpoint(small only) { 
        margin-top: -4rem;
        font-size: 15rem!important;
    }
}
.highlight {
    font-size: 2.5rem;
    @include breakpoint(small only) { 
        font-size: 1.4rem;
    }
}
.highlight2 {
    font-size: 1.6rem;
    @include breakpoint(small only) {
        font-size: 1.3rem;
    }
}
.noscroll {
    overflow-x: hidden;
}
.menu-active {
    visibility: visible!important;
    opacity: 1!important;
}
.verticalcenter {
    margin: 0;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%)
}
.mobilemenu{
    transition: all .8s;
    visibility: hidden;
    opacity: 0;
    background-color: black;
    position: fixed;
    z-index: 1000;
    width: 100%;
    height: 100%;
    top: 0;
    .menu {
        a {
            color: white;
            font-size: 2.5rem;
        }
    }
    .menu.vertical {
        a {
            padding: 5% 0!important;
        }
    }
    
}
.p5 {
    padding: 5% 0;
}
$bar-width: 30px;
$bar-height: 2px;
$bar-spacing: 7px;
.menu-wrapper {
	position: absolute;
    z-index: 1500;
  top: 0;
  right: 2rem;
  bottom: 0;
  width: $bar-width;
	height: $bar-height + $bar-spacing*2;
	cursor: pointer;
}
.hamburger-menu,
.hamburger-menu:after,
.hamburger-menu:before {
  width: $bar-width;
	height: $bar-height;
}

.hamburger-menu {
	position: relative;
	transform: translateY($bar-spacing);
	background:white;
	transition: all 0ms 300ms;
  
  &.animate {
    background: rgba(255, 255, 255, 0); 
  }
}

.hamburger-menu:before {
	content: "";
	position: absolute;
	left: 0;
	bottom: $bar-spacing;
	background: white;
	transition: bottom 300ms 300ms cubic-bezier(0.23, 1, 0.32, 1), transform 300ms cubic-bezier(0.23, 1, 0.32, 1);
}

.hamburger-menu:after {
	content: "";
	position: absolute;
	left: 0;
	top: $bar-spacing;
	background: white;
	transition: top 300ms 300ms cubic-bezier(0.23, 1, 0.32, 1), transform 300ms cubic-bezier(0.23, 1, 0.32, 1);
}

.hamburger-menu.animate:after {
	top: 0;
	transform: rotate(45deg);
	transition: top 300ms cubic-bezier(0.23, 1, 0.32, 1), transform 300ms 300ms cubic-bezier(0.23, 1, 0.32, 1);;
}

.hamburger-menu.animate:before {
	bottom: 0;
	transform: rotate(-45deg);
	transition: bottom 300ms cubic-bezier(0.23, 1, 0.32, 1), transform 300ms 300ms cubic-bezier(0.23, 1, 0.32, 1);;
}